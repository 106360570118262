import React from 'react';
import { CommonPropTypes } from '../Icons.types';
const Vald: React.FC<CommonPropTypes> = ({
  className
}) => {
  return <svg className={className} width='100' height='22' viewBox='0 0 100 22' fill='none' xmlns='http://www.w3.org/2000/svg' data-sentry-element="svg" data-sentry-component="Vald" data-sentry-source-file="Vald.tsx">
      <path d='M76.4717 0V22H77.7841L89.4437 21.9862C89.9756 21.9793 90.5075 21.9171 91.0324 21.8135C97.0073 20.6185 100.82 14.9821 99.7219 8.9865C98.7756 3.81287 94.2789 0.0414441 89.0155 0.0138145L76.4717 0ZM89.2987 17.2477C88.3524 17.3444 87.3992 17.303 86.439 17.303C84.7329 17.3099 82.9508 17.303 81.2447 17.303V4.71774C83.7175 4.71774 86.1904 4.70393 88.6632 4.73156C91.9235 4.76609 94.645 7.35636 94.8315 10.4854C95.0594 14.2154 92.3034 16.9369 89.2987 17.2477Z' fill='#FF7A20' data-sentry-element="path" data-sentry-source-file="Vald.tsx" />
      <path d='M41.2374 15.7488H35.7184C35.304 16.7987 34.9103 17.8072 34.482 18.9055H42.4738C42.9021 19.9692 43.3027 20.9777 43.7103 21.9931H49.2431C46.3489 14.6298 43.4754 7.31491 40.595 0C38.4607 0 36.3677 0 34.2402 0C31.3599 7.33564 28.4864 14.6436 25.5991 21.9931H31.0352L37.4176 5.99561L41.2374 15.7488Z' fill='#FF7A20' data-sentry-element="path" data-sentry-source-file="Vald.tsx" />
      <path d='M54.7268 22H69.9921C70.5999 20.4458 71.194 18.9193 71.8295 17.2961H59.4031V0H54.7061V13.4832L54.7268 22Z' fill='#FF7A20' data-sentry-element="path" data-sentry-source-file="Vald.tsx" />
      <path d='M23.6578 0L23.6509 0.0069078L15.0235 21.9931H15.0097H8.64804H8.63422L0 0.0069078V0H5.53281V0.0069078L11.7978 15.9146L18.1181 0.0069078L18.125 0H23.6578Z' fill='#FF7A20' data-sentry-element="path" data-sentry-source-file="Vald.tsx" />
    </svg>;
};
export default Vald;